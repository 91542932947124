
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import useOpenInfo from '@/hooks/openInfo'
import useSaveOpenInfo from '@/hooks/useSaveOpenInfo'
import useMutationCommon from '@/hooks/useMutationCommon'
import stepPercentage from '@/utils/number'
import { setApptitle } from '@/utils/native-app'
import UserAgent from '@/utils/useragent'

const cantoneseAudio = `${process.env.VUE_APP_H5_STATIC_PAGE_URL +
  '/static_pages/audio/risk-cantonese.mp3'}`
const mandarinAudio = `${process.env.VUE_APP_H5_STATIC_PAGE_URL +
  '/static_pages/audio/risk-mandarin.mp3'}`

export default defineComponent({
  setup () {
    const router = useRouter()
    const {
      openType,
      openInfo,
      meta,
      title,
      step,
      saveOpenInfoOfVuex,
      saveCommonOfVuex
    } = useOpenInfo()
    // 粤语播放器节点
    const cantoneseEl = ref()
    const cantoneseTime = ref(7 * 60 * 1000 + 7 * 1000)
    const mandarinTime = ref(5 * 60 * 1000 + 59 * 1000)
    // 普通话播放器节点
    const mandarinEl = ref()
    // 粤语倒计时节点
    const cantoneseCountEl = ref()
    // 普通话倒计时节点
    const mandarinCountEl = ref()
    const cantoneseAutoStart = ref(false)
    const mandarinAutoStart = ref(false)

    const riskChecked = ref(true)

    // 播放状态
    const audioStatus = ref(false)

    // 倒计时状态
    const timerStatus = ref(false)

    const licensedTime = ref()

    // 粤语音频文件cantonese
    const cantonese = reactive({
      url: cantoneseAudio,
      status: false
    })

    // 普通话音频文件
    const mandarin = reactive({
      url: mandarinAudio,
      status: false
    })

    const intiData = () => {
      audioStatus.value = openInfo.value.audioStatus
      licensedTime.value = openInfo.value.licensedTime
    }

    // 粤语切换
    const changeCantonesePlay = () => {
      // 先关闭普通话音频
      mandarinEl.value.pause()
      mandarin.status = false
      mandarinCountEl.value.pause()
      if (cantonese.status) {
        cantoneseEl.value.pause()
        cantoneseCountEl.value.pause()
      } else {
        cantoneseEl.value.play()
        cantoneseCountEl.value.start()
      }
      cantonese.status = !cantonese.status
    }

    // 普通话切换
    const changeMandarinPlay = () => {
      // 先关闭粤语音频
      cantoneseEl.value.pause()
      cantonese.status = false
      cantoneseCountEl.value.pause()
      if (mandarin.status) {
        mandarinEl.value.pause()
        mandarinCountEl.value.pause()
      } else {
        mandarinEl.value.play()
        mandarinCountEl.value.start()
      }
      mandarin.status = !mandarin.status
    }

    // 听完录音倒计时
    const finish = () => {
      console.log(new Date().getTime())
      licensedTime.value = new Date().getTime().toString()
      console.log(licensedTime.value)
      timerStatus.value = true
      cantonese.status = false
      mandarin.status = false
      if (cantoneseEl.value.ended) {
        cantoneseTime.value = 7 * 60 * 1000 + 7 * 1000
        cantoneseAutoStart.value = false
        audioStatus.value = true
        cantoneseCountEl.value.reset()
        cantoneseEl.value.load()
      }
      if (mandarinEl.value.ended) {
        mandarinTime.value = 5 * 60 * 1000 + 59 * 1000
        mandarinAutoStart.value = false
        audioStatus.value = true
        mandarinCountEl.value.reset()
        mandarinEl.value.load()
      }
    }

    const isDisabled = computed(() => {
      if (openInfo.value.audioStatus && riskChecked.value) {
        return false
      }

      if (timerStatus.value && riskChecked.value) {
        return false
      }
      return true
    })

    // 保存下一步调用接口
    const saveNext = () => {
      const percentage = stepPercentage(step)
      const params = {
        formType: 'openAccount',
        meta: meta.value,
        content: JSON.stringify({
          audioStatus: true,
          riskChecked: riskChecked.value,
          licensedTime: licensedTime.value
        })
      }
      const common = {
        openType: openType.value,
        openStatus: 0,
        btnText: '继续开户',
        remark: `已完成${percentage}%，请继续完善资料`,
        step
      }
      useSaveOpenInfo(params).then(() => {
        useMutationCommon(common)
        saveCommonOfVuex({ info: common })
        saveOpenInfoOfVuex({
          info: {
            audioStatus: true,
            riskChecked: riskChecked.value,
            licensedTime: licensedTime.value
          }
        })
        router.push({ name: 'other' })
      })
    }

    const handleNext = () => {
      const cantoneseStatus = cantoneseEl.value.ended
      const mandarinStatus = mandarinEl.value.ended
      if (audioStatus.value || cantoneseStatus || mandarinStatus) {
        saveNext()
      }
    }
    // 监听进度条拉录音时间
    // const watchCantoneseTime = () => {
    //   const restTime = Math.floor(254 - cantoneseEl.value.currentTime) * 1000
    //   cantoneseTime.value = restTime < 0 ? 0 : restTime
    //   cantoneseAutoStart.value = true
    //   cantoneseCountEl.value.start()
    // }
    // const watchMandarinTime = () => {
    //   const restTime = Math.floor(256 - mandarinEl.value.currentTime) * 1000
    //   mandarinTime.value = restTime < 0 ? 0 : restTime
    //   mandarinAutoStart.value = true
    //   mandarinCountEl.value.start()
    // }
    // 监听进度条暂停播放
    const playCantonese = () => {
      console.log('play')
      cantonese.status = true
      cantoneseCountEl.value.start()
    }
    const pauseCantonese = () => {
      console.log('pause')
      cantonese.status = false
      cantoneseCountEl.value.pause()
    }
    const playMandarin = () => {
      mandarin.status = true
      mandarinCountEl.value.start()
    }
    const pauseMandarin = () => {
      mandarin.status = false
      mandarinCountEl.value.pause()
    }

    onMounted(() => {
      setApptitle(title)
      intiData()
      console.dir(cantoneseEl.value)
      // cantoneseEl.value.addEventListener('seeked', watchCantoneseTime, false)
      cantoneseEl.value.addEventListener('ended', finish, false)
      // mandarinEl.value.addEventListener('seeked', watchMandarinTime, false)
      mandarinEl.value.addEventListener('ended', finish, false)
      if (UserAgent.isIos()) {
        cantoneseEl.value.addEventListener('play', playCantonese, false)
        cantoneseEl.value.addEventListener('pause', pauseCantonese, false)
        mandarinEl.value.addEventListener('play', playMandarin, false)
        mandarinEl.value.addEventListener('pause', pauseMandarin, false)
      }
    })
    onBeforeUnmount(() => {
      // cantoneseEl.value.removeEventListener('seeked', watchCantoneseTime, false)
      // mandarinEl.value.removeEventListener('seeked', watchMandarinTime, false)
      cantoneseEl.value.removeEventListener('ended', finish, false)
      mandarinEl.value.removeEventListener('ended', finish, false)
      if (UserAgent.isIos()) {
        mandarinEl.value.removeEventListener('paly', playMandarin, false)
        mandarinEl.value.removeEventListener('pause', pauseMandarin, false)
        cantoneseEl.value.removeEventListener('play', playCantonese, false)
        cantoneseEl.value.removeEventListener('pause', pauseMandarin, false)
      }
    })
    const closeAudio = () => {
      cantoneseTime.value = 7 * 60 * 1000 + 7 * 1000
      cantoneseAutoStart.value = false
      cantoneseCountEl.value.reset()
      cantoneseEl.value.load()
      cantonese.status = false
      mandarin.status = false
      mandarinTime.value = 5 * 60 * 1000 + 59 * 1000
      mandarinAutoStart.value = false
      mandarinCountEl.value.reset()
      mandarinEl.value.load()
    }
    const toClient = () => {
      closeAudio()
      window.location.href = `${process.env.VUE_APP_H5_STATIC_PAGE_URL}/static_pages/client_statement.html`
    }
    const toRisk = () => {
      closeAudio()
      window.location.href = `${process.env.VUE_APP_H5_STATIC_PAGE_URL}/static_pages/risk_statement.html`
    }
    return {
      isDisabled,
      handleNext,
      riskChecked,
      cantonese,
      mandarin,
      cantoneseEl,
      mandarinEl,
      mandarinTime,
      cantoneseTime,
      cantoneseAutoStart,
      mandarinAutoStart,
      changeCantonesePlay,
      changeMandarinPlay,
      cantoneseCountEl,
      mandarinCountEl,
      timerStatus,
      finish,
      toClient,
      toRisk
    }
  }
})
